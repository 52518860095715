<template>
  <modal-wrapper :id="id" hide-footer size="md">
    <template #title>
      Nuova Azienda
    </template>
    <template #body>
      <div>
        <b-form @submit.prevent="submitAzienda">
          <b-row>
            <b-col md="12">
              <field-text
                fieldId="companyName"
                placeholder="Ragione Sociale"
                :state="validateState($v.formAzienda.companyName)"
                v-model="$v.formAzienda.companyName.$model"
                title="Ragione Sociale"
              />
            </b-col>
            <b-col md="12">
              <field-text
                fieldId="vatNumber"
                placeholder="Partita Iva"
                :state="validateState($v.formAzienda.vatNumber)"
                v-model="$v.formAzienda.vatNumber.$model"
                title="Partita Iva"
              />
            </b-col>
            <b-col md="12">
              <field-select
                title="Rapporto con l'azienda"
                :options="relationTypes"
                :state="validateState($v.formAzienda.relationType)"
                v-model="$v.formAzienda.relationType.$model"
              ></field-select>
            </b-col>
          </b-row>
          <b-row class="mt-2 pt-3 border-top text-right">
            <b-col md="12">
              <button-loader
                content="Invia"
                :disabled="$v.formAzienda.$invalid"
                button-class="btn-success btn-sm"
              />
            </b-col>
          </b-row>
        </b-form>
      </div>
    </template>
  </modal-wrapper>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import lodash from 'lodash';
import {
  removeProp,
  validateFormFieldState,
} from '@/utils/validators';

const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');
const ButtonLoader = () => import('../ButtonLoader.vue');
const FieldSelect = () => import('../fields/FieldSelect.vue');
const FieldText = () => import('../fields/FieldText.vue');

const CONTEXT = 'person_company';

export default {
  name: 'ModalAddCompany',
  components: {
    FieldText,
    FieldSelect,
    ButtonLoader,
    ModalWrapper,
  },
  props: {
    id: String,
  },
  data() {
    return {
      submitted: false,
      formAzienda: {
        companyName: '',
        vatNumber: '',
        relationContext: 'person_company',
        relationType: 'legal',
      },
      clientValidation: {
        formAzienda: {
          companyName: {
            required,
          },
          vatNumber: {
            required,
          },
          relationType: {
            required,
          },
        },
      },
      serverValidation: {},
    };
  },
  created() {},
  computed: {
    rules() {
      // When the serverValidation property is set this computed property
      // fires and merges the client and server validation
      return lodash.merge({}, this.serverValidation, this.clientValidation);
    },
    relationTypes() {
      return this.$store.getters['tableUtils/getTableList'](CONTEXT) || [];
    },
  },
  mounted() {
    if (!this.$store.getters['tableUtils/hasTableList'](CONTEXT)) {
      if (!this.$store.getters['tableUtils/isLoadingTableList'](CONTEXT)) {
        this.$store.dispatch('tableUtils/loadTableList', {
          tableName: CONTEXT,
          language: 'it',
        });
      }
    }
  },
  validations() {
    return this.rules;
  },
  methods: {
    validateState: validateFormFieldState,
    clearServerErrors() {
      // Clearing the server validation removes the rules and
      // therefore server errors no longer contribute to validation state.
      this.serverValidation = {};
      // Clearing the server errors from the data isn't necessary either
      // but again makes for good housekeeping and we'd need to do this
      // if submitting this data to the server.
      removeProp(this.formData, 'serverErrors');
    },
    clearServerError(model, fieldName) {
      // When an input control is modified we see if there are any
      // server errors and clear them. This causes the serverError validation
      // rule to trigger and clear it's error state.
      // eslint-disable-next-line no-prototype-builtins
      if (model.hasOwnProperty('serverErrors')) {
        // eslint-disable-next-line no-prototype-builtins
        if (model.serverErrors.hasOwnProperty(fieldName)) {
          // eslint-disable-next-line no-param-reassign
          delete model.serverErrors[fieldName];
        }
      }
    },
    submitAzienda() {
      // const companyData = {
      //   companyName: this.formAzienda.companyName,
      //   vatNumber: this.formAzienda.vatNumber,
      //   relationContext: 'person_company',
      //   relationType: this.formAzienda.relationType,
      //   entityType: 'person',
      // };
      this.clearServerErrors();
      if (this.$v.formAzienda.$invalid) return;
      this.submitted = true;
      /*
      this.$store.dispatch('subject/connectCompany', companyData).then(
        (response) => {
          this.message = response.message;
          this.successful = true;
          this.submitted = false;
          this.$bvModal.hide(this.id);
        },
        (error) => {
          this.message = extractErrorMessage(error);

          const { field } = error;
          if (isPresent(field)) {
            const serverMessages = {
              serverErrors: {},
            };

            serverMessages.serverErrors[field] = this.message;

            // Merge the server errors into the data. This doesn't yet cause
            // any validation errors but does make the messages (and therefore state)
            // accessible to the template.
            lodash.merge(this.formAzienda, serverMessages);
            // From the serverMessage we should create a validation object which will
            // be merged with the client validation objects. It's at this point the
            // computed property changes and Vuelidate updates it's validation
            this.serverValidation = {
              formAzienda: {},
            };
            this.serverValidation.formAzienda[field] = {
              serverError: serverError(field, false),
            };
          }

          this.successful = false;
          this.submitted = false;
        },
      );
      */
    },
  },
};
</script>

<style scoped>

</style>
